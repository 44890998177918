<template>
  <div class="pad-home">
    <HeaderBar title="消息中心" :showLeft="true"></HeaderBar>
    <div class="announce-title">{{announceDetail.Name}}</div>
    <div v-html="announceDetail.NContent"
         class="announce-content"></div>
  </div>
</template>

<script>
import HeaderBar from '@/components/headerbar/headerbar'

import { getNoticeDetail } from '@/service/'

export default {
  name: 'announceDetail',
  components: {
    HeaderBar,
  },
  data () {
    return {
      announceDetail: ''
    }
  },
  computed: {
    announceId () {
      return this.$route.params.id
    }
  },
  methods: {
    async getDetail (id) {
      let res = await getNoticeDetail(id)
      if (res.code == 1) {
        this.announceDetail = res.data
      }
    }
  },
  mounted () {
    if(this.announceId.indexOf("ID") == -1 ){
      this.getDetail(this.announceId)
    }else{
      this.announceDetail=JSON.parse(this.$route.params.id)
    }
    
  }
}
</script>

<style lang="less" scoped>
.pad-home{
  padding-top: 94px;
}

.announce {
  &-title {
    margin: 50px auto 30px;
    text-align: center;
    font-size: 36px;
  }
  &-content {
    padding: 0 30px;
    line-height: 48px;
    font-size: 26px;
  }
}
</style>
